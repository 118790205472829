import { Controller } from '@hotwired/stimulus'

/* eslint-disable no-console */
export default class extends Controller {
  static targets = ['toggleSection']

  connect() {
    console.log('toggle controller connected.')
  }

  toggle(event) {
    event.preventDefault()
    this.toggleSectionTarget.classList.toggle('hidden')
  }
}
/* eslint-enable no-console */
