import VueBaseController from '../vue_base'


export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/keyword_clusters/show.vue') }

  static values = {
    clusteringId: String,
    clusterId: String,
  }

  connect() {
    super.connect({
      clusteringId: this.clusteringIdValue,
      clusterId: this.clusterIdValue
    })
  }
}
