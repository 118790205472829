import VueBaseController from '../vue_base'

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/show.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean,
    gaEnabled: Boolean,
    gaConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue,
      gaEnabled: this.gaEnabledValue,
      gaConnected: this.gaConnectedValue
    })
  }
}

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/index.vue') }

  static values = {
    clientId: String
  }

  connect() {
    super.connect({
      clientId: this.clientIdValue,
    })
  }
}

export class TrendsController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/trends.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean,
    gaEnabled: Boolean,
    gaConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue,
      gaEnabled: this.gaEnabledValue,
      gaConnected: this.gaConnectedValue
    })
  }
}

export class ExplorerController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/gsc_explorer.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue
    })
  }
}

export class QuestionsController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/gsc_questions.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue
    })
  }
}

export class RankController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/rank_trends.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue
    })
  }
}

export class CoverageController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/gsc_coverage.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue
    })
  }
}

export class CanonicalsController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/gsc_canonicals.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue
    })
  }
}

export class CrawlController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/gsc_crawl.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue
    })
  }
}

export class GraphController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/websites/d3_page_graph.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    ready: Boolean,
    inProgress: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      ready: this.readyValue,
      inProgress: this.inProgressValue
    })
  }
}
