import VueBaseController from '../vue_base'

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/recommendations/new.vue') }
}

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/recommendations/index.vue') }


  static values = {
   clientId: String
  }

  connect() {
    super.connect({
      clientId: this.clientIdValue
    })
  }
}
