// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { identifierForGlobKey } from 'stimulus-vite-helpers'
import application from './application'

const CONTROLLER_KEY = 'Controller'

// grab all controller files
const controllerFiles = import.meta.globEager('./**/*_controller.js')

// loop over all files and collect all the exports from them
const allControllers = Object.entries(controllerFiles)
  .reduce((mem, [name, controllerModule]) => {
    const baseIdentifier = identifierForGlobKey(name)

    // loop over all controller file exports
    const moduleControllers = Object.keys(controllerModule)
      .reduce((acc, key) => {
        // default export case
        if (key === 'default') {
          acc.push({ identifier: baseIdentifier, controllerConstructor: controllerModule.default })

          return acc
        }

        // named exports case
        if (key.includes(CONTROLLER_KEY)) {
          acc.push({
            identifier: `${baseIdentifier}--${key.replace(CONTROLLER_KEY, '').toLowerCase()}`,
            controllerConstructor: controllerModule[key]
          })

          return acc
        }

        // we do not want to handle other export cases
        return acc
      }, [])

    if (moduleControllers) {
      return [...mem, ...moduleControllers]
    }

    return mem
  }, [])

application.load(allControllers)
