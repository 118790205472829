<template>
  <component :is="errorComponent" v-if="errorComponent" />

  <Suspense>
    <slot />
  </Suspense>
</template>

<script setup>
import { shallowRef, onErrorCaptured } from 'vue'

import Error401 from './error_401.vue'
import Error404 from './error_404.vue'
import Error500 from './error_500.vue'

const errorComponent = shallowRef(null)

const knownErrorCodes = {
  401: Error401,
  404: Error404,
  500: Error500
}

onErrorCaptured((err) => {
  errorComponent.value = knownErrorCodes[err?.response?.status] || Error500
})
</script>
