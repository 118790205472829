import VueBaseController from '../vue_base'

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/onboardings/show.vue') }

  static values = {
    accountId: String
  }

  connect() {
    super.connect({
      accountId: this.accountIdValue
    })
  }
}
