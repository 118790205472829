import axios from 'axios'
import Qs from 'qs'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

const JSON_TYPE = 'application/json'
const securedAxiosInstance = axios.create({
  withCredentials: false,
  headers: {
    Accept: JSON_TYPE,
    'Content-Type': JSON_TYPE
  }
})

securedAxiosInstance.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

securedAxiosInstance.defaults.transformResponse = [(data, headers) => {
  if (data && headers && (headers['Content-Type'] + headers['content-type']).toString().includes(JSON_TYPE)) {
    return camelCaseKeys(JSON.parse(data), { deep: true })
  }
  return data
}]

securedAxiosInstance.defaults.transformRequest = [(data, headers) => {
  if (data && headers && typeof headers['Content-Type'] === 'string' && headers['Content-Type'].includes(JSON_TYPE)) {
    return JSON.stringify(snakeCaseKeys(data, { deep: true }))
  }
  return data
}]

securedAxiosInstance.defaults.paramsSerializer = (prms) => Qs.stringify(snakeCaseKeys(prms, { deep: true }), { arrayFormat: 'brackets' })

export const joinPaths = (...paths) => paths.join('/')
export default securedAxiosInstance
