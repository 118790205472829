import VueBaseController from './vue_base'

export class IndexController extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get COMPONENT() { return () => import('@/vue/components/system_notifications.vue') }

  static values = {
    apiBase: String
  }

  connect() {
    super.connect({
      apiBase: this.apiBaseValue
    })
  }
}
