function handleWlLogoInput() {
  const wlLogoInput = document.querySelector('#js-wl-configuration-logo')
  if (!wlLogoInput) return

  wlLogoInput.addEventListener('change', () => {
    wlLogoInput.closest('form').submit()
  })
}

function updateSecondaryNavByCollapsing() {
  const sidenav = document.querySelector('#secondary-sidenav-collapsing')
  if (!sidenav) return

  const collapseBtn = document.createElement('button')
  collapseBtn.setAttribute('type', 'button')
  collapseBtn.setAttribute('class', 'fixed right-3 bottom-14 w-9 h-12 flex items-center justify-center')

  const collapseBtnContent = document.createElement('i')
  collapseBtnContent.setAttribute('class', 'fa-regular fa-chevron-left')
  collapseBtn.appendChild(collapseBtnContent)
  sidenav.appendChild(collapseBtn)

  const collapseMenu = () => {
    collapseBtnContent.classList.toggle('fa-chevron-left')
    collapseBtnContent.classList.toggle('fa-chevron-right')

    sidenav.classList.toggle('collapsed')
    collapseBtn.classList.toggle('right-3')
    collapseBtn.classList.toggle('bg-white')

    document.querySelector('main').classList.toggle('lg:ml-80')
    document.querySelector('main').classList.toggle('lg:ml-32')
  }

  collapseBtn.addEventListener('click', collapseMenu)
}

document.addEventListener('DOMContentLoaded', () => {
  handleWlLogoInput()
  updateSecondaryNavByCollapsing()
})
