import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/form_templates/index.vue') }
}

export class FormController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/form_templates/form.vue') }

  static values = {
    formTemplateId: String
  }

  connect() {
    super.connect({
      formTemplateId: this.formTemplateIdValue
    })
  }
}
