import { createApp } from 'vue'
import VueBaseController from '../../vue_base'
import ContentInfo from '@/vue/pages/clients/ai_content/contents/show.vue'

export class IndexController extends VueBaseController {
  static values = {
    contentTypeId: String,
    locationId: String
  }

  connect() {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    this.mountVueComponent()
  }

  open(event) {
    event.preventDefault()
    this.unmountVueComponent()
    this.mountVueComponent()
  }

  mountVueComponent() {
    const prms = {
      contentTypeId: this.contentTypeIdValue,
      locationId: this.locationIdValue
    }
    this.vueApp = createApp(ContentInfo, prms)
    this.vueApp.mount('#content-info-widget')
  }

  unmountVueComponent() {
    if (this.vueApp) {
      this.vueApp.unmount()
      this.vueApp = null
    }
  }

  disconnect() {
    this.unmountVueComponent()
  }
}
