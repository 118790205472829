import { ref, computed } from 'vue'

import {
  getCart, addItem, removeItem, clearItems, checkout
} from '@/api/clients/cart'

const items = ref([])
const isSyncing = ref(true)
const itemsCount = computed(() => items.value.length)
const totalPrice = computed(() => items.value.reduce((memo, { totalPriceCents }) => memo + totalPriceCents, 0))

function changeCart(action, ...params) {
  isSyncing.value = true
  return action(...params)
    .then((res) => {
      if (res.data.items) {
        items.value = res.data.items
      }

      return Promise.resolve(res) // for chaining in componets
    })
    .finally(() => { isSyncing.value = false })
}

export default function useCart() {
  return {
    items,
    isSyncing,
    itemsCount,
    totalPrice,
    getCart: () => changeCart(getCart),
    addItem: (params) => changeCart(addItem, params),
    removeItem: (id) => changeCart(removeItem, id),
    clearItems: () => changeCart(clearItems),
    checkout: () => changeCart(checkout)
  }
}
