import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/press_releases/index.vue') }

  static values = {
    publicReportUrl: String
  }

  connect() {
    super.connect({
      publicReportUrl: this.publicReportUrlValue
    })
  }
}

export class FormController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/press_releases/form.vue') }

  static values = {
    restrictedWords: Array,
    pressReleaseId: String
  }

  connect() {
    super.connect({
      restrictedWords: this.restrictedWordsValue,
      pressReleaseId: this.pressReleaseIdValue
    })
  }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/press_releases/show.vue') }

  static values = {
    pressReleaseId: String,
    publicReportUrl: String
  }

  connect() {
    super.connect({
      pressReleaseId: this.pressReleaseIdValue,
      publicReportUrl: this.publicReportUrlValue
    })
  }
}
