import VueBaseController from '../vue_base'

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/partnership_program/show.vue') }

  static values = { accountId: String }

  connect() {
    super.connect({
      accountId: this.accountIdValue
    })
  }
}

export class CasesController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/partnership_program/case_studies.vue') }
}

export class TestsController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/partnership_program/seo_tests.vue') }
}

export class ProposalsController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/proposal_generator/index.vue') }

  static values = { accountId: String }

  connect() {
    super.connect({
      accountId: this.accountIdValue
    })
  }
}

export class PricingController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/partnership_program/pricing_tools.vue') }
}

export class GscController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/partnership_program/gsc_tools.vue') }
}
