import VueBaseController from '../vue_base'

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/proposal_generator/new.vue') }

  static values = {
    accountName: String,
    email: String,
    phone: String,
  }

  connect() {
    super.connect({
      accountName: this.accountNameValue,
      accountEmail: this.emailValue,
      accountPhone: this.phoneValue
    })
  }
}

