import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/businesses/locations/index.vue') }

  static values = {
    businessId: String
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
}


export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/businesses/locations/show.vue') }

  static values = {
    businessId: String,
    locationId: String,
    businessName: String,
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      locationId: this.locationIdValue,
      businessName: this.businessNameValue
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
}

export class KeywordsController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/businesses/locations/keywords.vue') }

  static values = {
    businessId: String,
    locationId: String,
    businessName: String,
    locationName: String,
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      locationId: this.locationIdValue,
      businessName: this.businessNameValue,
      locationName: this.locationNameValue
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
}
