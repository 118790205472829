import VueBaseController from '../../vue_base'
import consumer from '@/channels/consumer'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/ai_content/location_content_entities/index.vue') }

  static values = {
    locationId: String
  }

  connect() {
    super.connect({
      locationId: this.locationIdValue
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
}
