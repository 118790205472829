import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/ai_content/long_form_contents/index.vue') }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/ai_content/long_form_contents/new.vue') }

  static values = {
    liveSerpDataReady: Boolean
  }

  connect() {
    super.connect(
      { liveSerpDataReady: this.liveSerpDataReadyValue },
      'Vfm'
    )
  }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/ai_content/long_form_contents/show.vue') }

  static values = {
    contentId: String,
    aiEnabled: Boolean
  }

  connect() {
    super.connect(
      { contentId: this.contentIdValue, aiEnabled: this.aiEnabledValue },
      'Markdown'
    )
  }
}

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/ai_content/long_form_contents/edit.vue') }

  static values = {
    contentId: String
  }

  connect() {
    super.connect(
      { contentId: this.contentIdValue },
      'Vfm'
    )
  }
}
