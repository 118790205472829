import VueBaseController from '../../vue_base'

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/ai_content/bulk_contents/show.vue') }

  static values = {
    bulkJobId: String,
    waiting: Boolean
  }

  connect() {
    super.connect({
      bulkJobId: this.bulkJobIdValue,
      waiting: this.waitingValue
    })
  }
}
