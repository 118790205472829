import VueBaseController from '../vue_base'

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/task_bundle_templates/form.vue') }

  static values = {
    bundleId: String
  }

  connect() {
    super.connect({
      bundleId: this.bundleIdValue
    })
  }
}

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/task_bundle_templates/index.vue') }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/task_bundle_templates/form.vue') }
}
