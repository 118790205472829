import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/teams/index.vue') }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/teams/new.vue') }
}

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/teams/edit.vue') }

  static values = {
    teamId: String
  }

  connect() {
    super.connect({
      teamId: this.teamIdValue
    })
  }
}
