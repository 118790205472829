import { createApp } from 'vue'
import VueBaseController from '../../vue_base'
import NewContentForm from '@/vue/pages/users/ai_content/location_content_entities/new.vue'

let app

export class IndexController extends VueBaseController {
  static values = {
    contentTypeId: String,
    locationId: String
  }

  connect() {
  }

  open(event) {
    event.preventDefault()

    const prms = {
      contentTypeId: this.contentTypeIdValue,
      locationId: this.locationIdValue
    }

    if (app) {
      app.unmount()
    }

    app = createApp(NewContentForm, prms)
    app.mount('#content-widget')
  }
}
