<template>
  <span :class="['inline-block text-xs font-medium rounded', badgeClass, badgeSize]">
    <slot />
  </span>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  kind: {
    type: String,
    default: 'default',
    validator(value) {
      return ['dark', 'default', 'green', 'indigo', 'pink', 'purple', 'red', 'yellow'].includes(value)
    }
  },
  size: {
    type: String,
    default: 'default',
    validator(value) {
      return ['default', 'lg'].includes(value)
    }
  }
})

// TODO: Can be simplified later when we decide how to declare ui components
const badgeClass = computed(() => ({
  dark: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300',
  default: 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300',
  green: 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300',
  indigo: 'bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300',
  pink: 'bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300',
  purple: 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300',
  red: 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300',
  yellow: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300'
}[props.kind]))

const badgeSize = computed(() => ({
  default: 'px-2 py-1',
  lg: 'px-2.5 py-1.5'
}[props.size]))
</script>
