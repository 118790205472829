import VueBaseController from '../vue_base'

export class MessagesController extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get COMPONENT() { return () => import('@/vue/components/notifications/notification_wrapper.vue') }

  connect() {
    super.connect({
      isClient: false,
      notificationsNamespace: 'messages',
      href: '/users/chats',
      title: 'Chats',
      iconClass: 'fa-regular fa-messages'
    })
  }
}

export class ApprovalsController extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get COMPONENT() { return () => import('@/vue/components/notifications/notification_wrapper.vue') }

  connect() {
    super.connect({
      isClient: false,
      notificationsNamespace: 'approvals',
      href: '/users/chats?approvals=true',
      title: 'Approvals',
      iconClass: 'fa-regular fa-thumbs-up'
    })
  }
}
