import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/website_pages/index.vue') }

  static values = {
    businessId: String,
    websiteId: String
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue
    })
  }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/website_pages/show.vue') }

  static values = {
    businessId: String,
    websitePageId: String,
    gscEnabled: Boolean,
    gaEnabled: Boolean,
    gscConnected: Boolean,
    gaConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websitePageId: this.websitePageIdValue,
      gscEnabled: this.gscEnabledValue,
      gaEnabled: this.gaEnabledValue,
      gscConnected: this.gscConnectedValue,
      gaConnected: this.gaConnectedValue

    })
  }
}

export class OptimizeController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/website_pages/optimize.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    websitePageId: String,
    optimizationStarted: Boolean,
    optimizationLocale: String,
    fullUrl: String,
    localEnabled: Boolean,
    perfEnabled: Boolean,
    localOptimizationProcedures: String
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websitePageId: this.websitePageIdValue,
      websiteId: this.websiteIdValue,
      optimizationStarted: this.optimizationStartedValue,
      optimizationLocale: this.optimizationLocaleValue,
      fullUrl: this.fullUrlValue,
      localEnabled: this.localEnabledValue,
      perfEnabled: this.perfEnabledValue,
      localOptimizationProcedures: this.localOptimizationProceduresValue
    })
  }
}
