import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/ai_content/bulk_contents/index.vue') }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/ai_content/bulk_contents/show.vue') }

  static values = { // описание ожидаемых контроллером параметров для стимулуса
    bulkJobId: String,
    waiting: Boolean,
    status: String
  }

  connect() {
    super.connect({ // заколить метод бейз класса с нужными пропсами
      bulkJobId: this.bulkJobIdValue,
      waiting: this.waitingValue,
      status: this.statusValue
    })
  }
}
