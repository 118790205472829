import VueBaseController from '../vue_base'

import CartNavComponent from '@/vue/components/cart_nav.vue'

export class NavController extends VueBaseController {
  static get COMPONENT() { return CartNavComponent }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/cart/show.vue') }
}
