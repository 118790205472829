import { Controller } from '@hotwired/stimulus'
import { createToast } from 'mosha-vue-toastify'
import consumer from '@/channels/consumer'

export default class extends Controller {
  static values = {
    locationId: String
  }

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: 'ContentMapChannel', location_id: this.locationIdValue }, {
      received: (data) => {
        createToast('Content Map created.', { type: 'success' })
        const tableBody = this.element.querySelector('tbody')
        tableBody.insertAdjacentHTML('beforeend', data)
      }
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
}
