<template>
  <a
    href="/clients/cart"
    class="relative secondary-nav__item">
    <i class="fa-regular fa-cart-shopping text-xl fa-fw" />
    <div class="secondary-nav-title flex items-center justify-between flex-1">
      <span>Cart</span>
      <UiBadge class="inline-flex items-center justify-center w-6 h-6 font-bold">
        <span v-if="cart.isSyncing.value"><i class="fa fa-spinner fa-spin" /></span>
        <span v-else>{{ cart.itemsCount }}</span>
      </UiBadge>
    </div>
    <div v-if="cart.itemsCount.value" class="small-notification" />
  </a>
</template>

<script setup>
import { UiBadge } from '@ui/badge'
import useCart from '@/vue/composables/use_cart'

const cart = useCart()
cart.getCart()
</script>
