import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/keyword_clusterings/index.vue') }

  static values = {
    userId: String
  }

  connect() {
    super.connect({
      userId: this.userIdValue
    })
  }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/keyword_clusterings/new.vue') }

  static values = {
    userId: String
  }

  connect() {
    super.connect({
      userId: this.userIdValue
    })
  }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/keyword_clusterings/show.vue') }

  static values = {
    clusteringId: String,
    generatingTopicalClusters: Boolean,
    generatedTopicalClusters: Boolean
  }

  connect() {
    super.connect({
      clusteringId: this.clusteringIdValue,
      generatingTopicalClusters: this.generatingTopicalClustersValue,
      generatedTopicalClusters: this.generatedTopicalClustersValue

    })
  }
}
