import { Controller } from '@hotwired/stimulus'

/* eslint-disable no-console */
export default class extends Controller {
  connect(_context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    console.log('connect: pages controller')
  }

  disconnect() {
    console.log('disconnect: pages controller')
  }
}
/* eslint-enable no-console */
