import VueBaseController from '../../vue_base'

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/ai_content/content_maps/show.vue') }

  static values = {
    contentMapId: String
  }

  connect() {
    super.connect({
      contentMapId: this.contentMapIdValue
    })
  }
}
