import VueBaseController from '../vue_base'

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/products/form.vue') }

  static values = {
    productId: String
  }

  connect() {
    super.connect({
      productId: this.productIdValue
    })
  }
}

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/products/index.vue') }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/products/form.vue') }
}
