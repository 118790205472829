import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/recommendations/index.vue') }


  static values = {
   clientId: String
  }

  connect() {
    super.connect({
      clientId: this.clientIdValue
    })
  }
}

export class StatsController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/recommendations/stats.vue') }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/recommendations/show.vue') }


  static values = {
   clientId: String,
   recommendationId: String,
   production: Boolean
  }

  connect() {
    super.connect({
      clientId: this.clientIdValue,
      recommendationId: this.recommendationIdValue,
      production: this.productionValue

    })
  }
}