import VueBaseController from '../../vue_base'

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/ai_content/content_types/new.vue') }
}

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/ai_content/content_types/edit.vue') }

  static values = {
    name: String,
    model: String,
    topP: Number,
    temperature: Number,
    frequencyPenalty: Number,
    presencePenalty: Number,
    maxTokens: Number,
    completions: Number,
    messages: Array,
    contentTypeId: String,
    regionalDialect: String
  }

  connect() {
    super.connect({
      currentName: this.nameValue,
      currentModel: this.modelValue,
      currentTopP: this.topPValue,
      currentTemperature: this.temperatureValue,
      currentFrequencyPenalty: this.frequencyPenaltyValue,
      currentPresencePenalty: this.presencePenaltyValue,
      currentMaxTokens: this.maxTokensValue,
      currentCompletions: this.completionsValue,
      currentMessages: this.messagesValue,
      contentTypeId: this.contentTypeIdValue,
      currentRegionalDialect: this.regionalDialectValue
    })
  }
}
