import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/agencies/index.vue') }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/agencies/show.vue') }

  static values = {
    agencyId: String
  }

  connect() {
    super.connect({
      agencyId: this.agencyIdValue
    })
  }
}

export class FormController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/agencies/form.vue') }

  static values = {
    agencyId: String
  }

  connect() {
    super.connect({
      agencyId: this.agencyIdValue
    })
  }
}
