import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/courses/index.vue') }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/courses/new.vue') }
}

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/courses/edit.vue') }

  static values = {
    courseId: String
  }

  connect() {
    super.connect({
      courseId: this.courseIdValue
    })
  }
}
