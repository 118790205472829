import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/keyword_maps/index.vue') }

  static values = {
    userId: String
  }

  connect() {
    super.connect({
      userId: this.userIdValue
    })
  }
}
