import VueBaseController from '../../vue_base'

export class ShowController extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get ASYNC_COMPONENT() { return () => import('@/vue/pages/users/algo/pages/show.vue') }

  static values = {
    projectId: String,
    pageId: String
  }
}
