import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import { createToast } from 'mosha-vue-toastify'

export default class extends Controller {
  submit(event) {
    event.preventDefault();
    this.toggleButtonLoading(true)

    const formElement = event.target;
    const formData = new FormData(formElement)
    const url = '/ajax/static/dashboard_previewers'

    axios.post(url, formData)
      .then(response => {
        createToast('Thanks for signing up for dashboard updates! successful.', { type: 'success' })
        formElement.reset()
        this.toggleButtonLoading(false)
      })
      .catch(error => {
        console.error('Submission error', error)
        createToast('Failed to subscribe.', { type: 'danger' })
        this.toggleButtonLoading(false)
      })
  }

  beta(event) {
    event.preventDefault();
    this.toggleButtonLoading(true)

    const formElement = event.target;
    const formData = new FormData(formElement)
    const url = '/ajax/static/dashboard_beta_testers'

    axios.post(url, formData)
      .then(response => {
        createToast('Thanks for signing up for dashboard beeta testing!', { type: 'success' })
        formElement.reset()
        this.toggleButtonLoading(false)
      })
      .catch(error => {
        console.error('Submission error', error)
        createToast('Failed to subscribe.', { type: 'danger' })
        this.toggleButtonLoading(false)
      })
  }

  toggleButtonLoading(isLoading) {
    const button = this.element.querySelector('input[type="submit"]')
    button.disabled = isLoading
    button.value = isLoading ? 'Loading...' : 'Subscribe'
  }
}
