import VueBaseController from '../vue_base'

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/product_schemas/form.vue') }

  static values = {
    productSchemasId: String
  }

  connect() {
    super.connect({
      productSchemasId: this.productSchemasIdValue
    })
  }
}

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/product_schemas/index.vue') }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/product_schemas/form.vue') }
}
