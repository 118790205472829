import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/chats/index.vue') }

  static values = {
    currentUserId: String
  }

  connect() {
    super.connect({
      currentUserId: this.currentUserIdValue
    })
  }
}
