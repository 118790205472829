import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/businesses/keywords/index.vue') }

  static values = {
    businessId: String,
    businessName: String,
    keywordVolumeEnabled: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      businessName: this.businessNameValue,
      keywordVolumeEnabled: this.keywordVolumeEnabledValue
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
}

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/businesses/keywords/edit.vue') }

  static values = {
    businessId: String
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/businesses/keywords/show.vue') }

  static values = {
    businessId: String,
    keywordId: String,
    keyword: String,
    keywordVolumeEnabled: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      keywordId: this.keywordIdValue,
      keyword: this.keywordValue,
      keywordVolumeEnabled: this.keywordVolumeEnabledValue
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
}