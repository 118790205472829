import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/teams/index.vue') }

  static values = {
    currentUserEmail: String
  }

  connect() {
    super.connect({
      currentUserEmail: this.currentUserEmailValue
    })
  }
}
