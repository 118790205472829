import axios from '../index'

export function getCart(params) {
  return axios.get('/ajax/clients/cart', { params })
}

export function addItem(cartItem) {
  return axios.post('/ajax/clients/cart/cart_items', { cartItem })
}

export function removeItem(id) {
  return axios.delete(`/ajax/clients/cart/cart_items/${id}`)
}

export function clearItems() {
  return axios.delete('/ajax/clients/cart')
}

export function checkout() {
  return axios.post('/ajax/clients/cart/checkout')
}
