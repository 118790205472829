import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/sites/index.vue') }
}

export class SettingsController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/sites/settings.vue') }
}

export class SetupController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/sites/setup.vue') }

  static values = { websiteId: String }

   connect() {
    super.connect({
      websiteId: this.websiteIdValue,
    })
  }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/sites/show.vue') }

  static values = {
    websiteId: String
  }

  connect() {
    super.connect({
      websiteId: this.websiteIdValue
    })
  }

}