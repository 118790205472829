import VueBaseController from '../vue_base'

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/sites/website_pages/edit.vue') }

  static values = {
    websitePageId: String,
    websiteId: String
  }

  connect() {
    super.connect({
      websitePageId: this.websitePageIdValue,
      websiteId: this.websiteIdValue
    })
  }

}

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/sites/website_pages/index.vue') }

  static values = {
    websiteId: String
  }

  connect() {
    super.connect({
      websiteId: this.websiteIdValue
    })
  }

}

