import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/smart_tasks/index.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean,
    gaEnabled: Boolean,
    gaConnected: Boolean,
    smartTasksEnabled: Boolean,
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue,
      gaEnabled: this.gaEnabledValue,
      gaConnected: this.gaConnectedValue,
      smartTasksEnabled: this.smartTasksEnabledValue,
    })
  }
}

export class SettingsController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/smart_tasks/settings.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean,
    gaEnabled: Boolean,
    gaConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue,
      gaEnabled: this.gaEnabledValue,
      gaConnected: this.gaConnectedValue
    })
  }
}

export class UsersController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/smart_tasks/users.vue') }

  static values = {
    businessId: String,
    websiteId: String,
    gscEnabled: Boolean,
    gscConnected: Boolean,
    gaEnabled: Boolean,
    gaConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      websiteId: this.websiteIdValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue,
      gaEnabled: this.gaEnabledValue,
      gaConnected: this.gaConnectedValue
    })
  }
}



