import { Controller } from '@hotwired/stimulus'

export class IndexController extends Controller {
  static values = { categoryName: String, searchQuery: String }
  static targets = ["modal", "categorySection", "contentType", "searchInput"]

  connect() {
    this.categorySectionTargets.forEach((section) => {
      section.style.display = 'block'
    })
  }

  searchContentTypes() {
    const query = this.searchQueryValue.toLowerCase()
    this.contentTypeTargets.forEach((contentType) => {
      const name = contentType.textContent.toLowerCase()
      const parentElement = contentType.parentElement

      if (name.includes(query)) {
        parentElement.style.display = 'block'
      } else {
        parentElement.style.display = 'none'
      }
    })
  }

  get searchQueryValue() {
    return this.searchInputTarget.value;
  }

  open(event) {
    event.preventDefault();
    if (this.hasCategoryNameValue) {
      this.categorySectionTargets.forEach((section) => {
        section.style.display = section.dataset.categoryName === this.categoryNameValue ? 'block' : 'none'
      })
    }
    this.modalTarget.classList.remove('hidden')
  }

  close(event) {
    event.preventDefault()
    this.modalTarget.classList.add('hidden')
  }

  closeModalBackground(event) {
    if (event.target === this.modalTarget) {
      this.modalTarget.classList.add('hidden')
    }
  }

  closeAndOpenDrawer(event) {
    event.preventDefault()
    this.modalTarget.classList.add('hidden')
    const contentTypeID = event.currentTarget.dataset.contentTypeId
    const eventDetail = { contentTypeId: contentTypeID, locationId: this.locationIdValue }
    const customEvent = new CustomEvent('open-drawer', { detail: eventDetail, bubbles: true })
    window.dispatchEvent(customEvent)
  }

  filterCategory(event) {
    event.preventDefault()
    const selectedCategory = event.currentTarget.dataset.categoryName

    this.categorySectionTargets.forEach((section) => {
      section.style.display = section.dataset.categoryName === selectedCategory ? 'block' : 'none'
    });
  }

  showAll(event) {
    event.preventDefault()
    this.categorySectionTargets.forEach((section) => {
      section.style.display = 'block'
    })
  }
}
