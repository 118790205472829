import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/companies/index.vue') }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/companies/show.vue') }

  static values = {
    companyId: String
  }

  connect() {
    super.connect({
      companyId: this.companyIdValue
    })
  }
}

export class FormController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/companies/form.vue') }

  static values = {
    companyId: String
  }

  connect() {
    super.connect({
      companyId: this.companyIdValue
    })
  }
}
