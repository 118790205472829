<template>
  <div :class="['flex items-center p-4 mb-4 text-sm border rounded-lg', alertClass]" role="alert">
    <i v-if="hasIcon" :class="[iconClass, 'mr-2']" />
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  kind: {
    type: String,
    default: 'default',
    validator(value) {
      return ['blue', 'red', 'red-light', 'purple', 'green', 'yellow', 'gray'].includes(value)
    }
  },
  iconClass: { type: String, default: 'fa-regular fa-circle-info' },
  hasIcon: { type: Boolean, default: true }
})

// TODO: Can be simplified later when we decide how to declare ui components
const alertClass = computed(() => ({
  blue: 'text-blue-800 border-blue-300 bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800',
  red: 'text-red-800 border-red-300 bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800',
  'red-light': 'text-red-800 border-transparent bg-red-100',
  purple: 'text-purple-800 border-transparent bg-purple-100',
  green: 'text-green-800 border-green-300 bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800',
  gray: 'text-gray-800 border-gray-300 bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600'
}[props.kind]))
</script>
