import { createApp } from 'vue'
import VueBaseController from '../../vue_base'
import NewContentForm from '@/vue/pages/clients/ai_content/location_content_entities/new.vue'

let app

export class IndexController extends VueBaseController {
  static values = { // описание ожидаемых контроллером параметров для стимулуса
    contentTypeId: String,
    locationId: String
  }

  connect() {
    this.element.addEventListener('open-drawer', this.handleOpenDrawerEvent.bind(this));
  }

  open(event) {
    event.preventDefault()
    const prms = {
      contentTypeId: this.contentTypeIdValue,
      locationId: this.locationIdValue
    }

    if (app) {
      app.unmount()
    }

    app = createApp(NewContentForm, prms)
    app.mount('#content-widget')
  }

  handleOpenDrawerEvent(event) {
    console.log(`event handled in handleOpenDrawer: ${event}`)
    const { contentTypeId, locationId } = event.detail;
    this.contentTypeIdValue = contentTypeId;
    this.locationIdValue = locationId;

    if (app) {
        app.unmount()
    } else {
      const prms = {
      contentTypeId: this.contentTypeIdValue,
      locationId: this.locationIdValue
    }
    app = createApp(NewContentForm, prms)
    app.mount('#content-widget')

    }
    this.open(event);
  }
}
