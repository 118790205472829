import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get COMPONENT() { return () => import('@/vue/pages/clients/courses/index.vue') }
}


export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/courses/show.vue') }

  static values = {
    courseId: String
  }

  connect() {
    super.connect({
      courseId: this.courseIdValue
    })
  }
}
