import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/categories/index.vue') }
}

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/categories/form.vue') }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/admin/categories/form.vue') }
}
