import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get ASYNC_COMPONENT() { return () => import('@/vue/pages/users/algo/projects/index.vue') }
}

export class FormController extends VueBaseController {
  static get ASYNC_COMPONENT() { return () => import('@/vue/pages/users/algo/projects/form.vue') }

  static values = {
    projectId: String,
    mapKey: String
  }
}

export class ShowController extends VueBaseController {
  static get ASYNC_COMPONENT() { return () => import('@/vue/pages/users/algo/projects/show.vue') }

  static values = {
    projectId: String
  }
}

export class BacklinksController extends VueBaseController {
  static get ASYNC_COMPONENT() { return () => import('@/vue/pages/users/algo/projects/backlinks.vue') }
}
