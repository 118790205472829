import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/websites/index.vue') }
}

export class FormController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/pr/websites/form.vue') }

  static values = {
    websiteId: String
  }

  connect() {
    super.connect({
      websiteId: this.websiteIdValue
    })
  }
}
