// app/javascript/controllers/theme_switch_controller.js

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["darkIcon", "lightIcon", "themeLabel"];

  connect() {
    this.updateTheme();
  }

  toggleTheme() {
    const theme = localStorage.getItem('color-theme');
    if (theme === 'dark' || (!theme && this.isSystemDarkMode())) {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('color-theme', 'light');
    } else {
      document.documentElement.classList.add('dark');
      localStorage.setItem('color-theme', 'dark');
    }
    this.updateTheme();
  }

  updateTheme() {
    const theme = localStorage.getItem('color-theme');
    if (theme === 'dark' || (!theme && this.isSystemDarkMode())) {
      this.darkIconTarget.classList.add('hidden');
      this.lightIconTarget.classList.remove('hidden');
      this.themeLabelTarget.textContent = 'Light Mode';
    } else {
      this.darkIconTarget.classList.remove('hidden');
      this.lightIconTarget.classList.add('hidden');
      this.themeLabelTarget.textContent = 'Dark Mode';
    }
  }


  isSystemDarkMode() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
}
