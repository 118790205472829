import Rails from '@rails/ujs'
import LocalTime from 'local-time'
import '~/controllers/index.js'
import 'flowbite'

window.Rails = Rails

// TODO: figure it out why we still need rails-ujs
// eslint-disable-next-line no-underscore-dangle
if (window._rails_loaded === undefined) Rails.start()
LocalTime.start()
