import VueBaseController from '../vue_base'


export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/keyword_maps/index.vue') }

  static values = {
    openaiEnabled: Boolean,
    statsEnabled: Boolean
  }

  connect() {
    super.connect({
      openaiEnabled: this.openaiEnabledValue,
      statsEnabled: this.statsEnabledValue
    })
  }

}

export class InitializeController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/keyword_maps/init.vue') }


  static values = {
    accountId: String
  }

  connect() {
    super.connect({
      accountId: this.accountIdValue
    })
  }
}
