import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import { createToast } from 'mosha-vue-toastify'

/* eslint-disable no-console */
export default class extends Controller {
  static values = {
    locationId: String,
    url: String,
    loadingState: String
  }

  connect(_context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log(this.locationIdValue)
    console.log('connect: content map btn controller')
  }

  generate(event) {
    event.preventDefault()
    axios.post(this.urlValue)
      .then((response) => {
        createToast('Content Map queued.', { type: 'success' })
      })
      .catch((error) => {
        createToast('Failed to create Content Map.', { type: 'danger' })
      })
  }

  disconnect() {
    console.log('disconnect: pages controller')
  }
}
/* eslint-enable no-console */
