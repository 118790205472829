import { Controller } from '@hotwired/stimulus'
import {
  createApp, h, defineComponent, defineAsyncComponent
} from 'vue'
import { createVfm } from 'vue-final-modal'
import Markdown from 'vue3-markdown-it'

import { WrapperComponent, LoadingComponent, ErrorComponent } from '@/vue/components/async_components'

// TODO: simplify all controllers after
function wrapComponent(callbackOrComponent) {
  if (typeof callbackOrComponent === 'function') {
    // chunked async component
    return defineComponent({
      setup(_props, { attrs: props }) {
        const TargetComponent = defineAsyncComponent({
          // the loader function
          loader: callbackOrComponent,

          // A component to use while the async component is loading
          loadingComponent: LoadingComponent,
          // Delay before showing the loading component. Default: 200ms.
          delay: 200,

          // A component to use if the load fails
          errorComponent: ErrorComponent,
          // The error component will be displayed if a timeout is
          // provided and exceeded. Default: Infinity.
          timeout: 3000
        })

        return () => h(WrapperComponent, {}, () => [h(TargetComponent, props)])
      }
    })
  }

  return callbackOrComponent
}

function wrapAsyncComponent(asyncComponent) {
  return defineComponent({
    setup(_props, { attrs: props }) {
      const TargetComponent = defineAsyncComponent({
        // the loader function
        loader: asyncComponent,

        // A component to use while the async component is loading
        loadingComponent: LoadingComponent,
        // Delay before showing the loading component. Default: 200ms.
        delay: 200,

        // A component to use if the load fails
        errorComponent: ErrorComponent,
        // The error component will be displayed if a timeout is
        // provided and exceeded. Default: Infinity.
        timeout: 3000
      })

      return () => h(WrapperComponent, {}, () => [h(TargetComponent, props)])
    }
  })
}

export default class extends Controller {
  connect(controllerProps = {}, usingComponent = '') {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    const { ASYNC_COMPONENT, COMPONENT } = this.constructor

    const stimulusProps = Object.keys(this.constructor.values).reduce((mem, key) => {
      mem[key] = this[`${key}Value`]

      return mem
    }, {})

    const app = ASYNC_COMPONENT ? wrapAsyncComponent(ASYNC_COMPONENT) : wrapComponent(COMPONENT)
    const vfm = createVfm()

    this.app = createApp(app, { ...stimulusProps, ...controllerProps })

    if (usingComponent === 'Vfm') {
      this.app.use(vfm).mount(this.element)
    } else if (usingComponent === 'Markdown') {
      this.app.use(Markdown).mount(this.element)
    } else {
      this.app.mount(this.element)
    }
  }

  disconnect() {
    // TODO: figure out should we unmoun or not
    this.app?.unmount()
  }
}
