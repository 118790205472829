import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/ai_content/content_entities/index.vue') }
}

export class NewController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/ai_content/content_entities/new.vue') }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/ai_content/content_entities/show.vue') }

  static values = {
    contentEntityId: String,
    title: String,
    contentScore: Number,
    totalTokens: Number,
    model: String,
    locationId: String
  }

  connect() {
    super.connect({
      contentEntityId: this.contentEntityIdValue,
      title: this.titleValue,
      contentScore: this.contentScoreValue,
      totalTokens: this.totalTokensValue,
      model: this.modelValue,
      locationId: this.locationIdValue
    })
  }
}
